<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-if="initColor && initColor.id">
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Изменить цвет</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editColorForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model="name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Ключ</label>
                <md-input v-model="key" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ChromePicker v-model="color" />

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="editColor">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { Chrome } from "vue-color";

export default {
  components: {
    ChromePicker: Chrome,
  },

  data() {
    return {
      name: "",
      key: "",
      color: "",
    };
  },

  computed: {
    ...mapState({
      initColor: (state) => state.colors.color,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    let success = await this.getColor(+this.$route.params.id);
    if (success) {
      this.name = this.initColor.name;
      this.key = this.initColor.key;
      this.color = this.initColor.hex;
    }
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("colors", ["getColor", "updateColor"]),

    async editColor() {
      const isValid = await this.$refs.editColorForm.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateColor({
        id: this.initColor.id,
        color: {
          name: this.name,
          key: this.key,
          hex: this.color?.hex || this.color,
        },
      });

      if (success) {
        this.$router.push("/colors");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>